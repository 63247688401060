import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

const ResearchPage = () => (
  <Layout>
    <SEO
      title="Research"
      meta={[
        {
          property: `robots`,
          content: `noindex`,
        },
      ]}
    />
    <div className="page research">
      <div className="container">
        <h1 className="pageTitle">A few questions about you and your pup</h1>

        {/*<!-- Wufoo Form -->*/}
        <form id="form5" name="form5" className="wufoo topLabel page" acceptCharset="UTF-8" autoComplete="off" encType="multipart/form-data" method="post" noValidate
              action="https://nscramer.wufoo.com/forms/s16mr30v0xpj0in/#public">
  
          <header id="header" className="info"></header>

          <label className="desc" id="title1" htmlFor="Field1">Why get a dog?</label>
          <div>
            <textarea id="Field1" name="Field1" className="field textarea medium" spellCheck="true"
          rows="10" cols="50" tabIndex="1" onKeyUp="" placeholder=""></textarea>
          </div>

          <label className="desc" id="title2" htmlFor="Field2">
            How do you imagine a product like Harper would change your life?
          </label>

          <div>
            <textarea id="Field2"
              name="Field2"
              className="field textarea medium"
              spellCheck="true"
              rows="10" cols="50"
              tabIndex="2"
              onKeyUp=""
              placeholder=""
            />
          </div>
          <label>
            What made you think to search for a dog training app?
          </label>

          <div>
            <textarea
              id="Field3"
              name="Field3"
              className="field textarea medium"
              spellCheck="true"
              rows="10" cols="50"
              tabIndex="3"
              onKeyUp=""
              placeholder=""
            />
          </div>

          <label>
            What does success look like for you with dog training?
          </label>

          <div>
            <textarea
              id="Field4"
              name="Field4"
              className="field textarea medium"
              spellCheck="true"
              rows="10" cols="50"

              tabIndex="4"
              onKeyUp=""
              placeholder=""
            />
          </div>

          <label>
            What does it look like when you feel defeated with dog training?
          </label>

          <div>
            <textarea
              id="Field5"
              name="Field5"
              className="field textarea medium"
              spellCheck="true"
              rows="10" cols="50"
              tabIndex="5"
              onKeyUp=""
              placeholder=""
            />
          </div>

          <label>What are your biggest obstacles to success?</label>

          <div>
            <textarea
              id="Field6"
              name="Field6"
              className="field textarea medium"
              spellCheck="true"
              rows="10" cols="50"
              tabIndex="6"
              onKeyUp=""
              placeholder=""
            />
          </div>
      
          <label>
            Are you up for a short phone call to talk more about your pup and dog training?
          </label>

          <div>
            <span className="subfield">
              <input id="Field9" name="Field9" type="checkbox" className="field checkbox" value="Yes, I&rsquo;m game to talk more" tabIndex="7" />
              <span className="choice__text notranslate" style={{ position: "relative", bottom: "2px" }}> Yes, I&rsquo;m game to talk more</span>
              <span className="choice__qty"></span>
            </span>
          </div>

          <div>
            <input id="saveForm" name="saveForm" className="btn btn--primary btn--block" type="submit" value="Submit" />
          </div>

          <input type="hidden" id="idstamp" name="idstamp" value="oB7A2iQUXnV3UIhLSvp2x62Z1xoszYJcz3AilDriAm8=" />
        </form>
      </div>
    </div>
  </Layout>
);

export default ResearchPage
